<!--
 * @Author: zhujun zhujun@yhb-work.com
 * @Date: 2024-09-30 10:41:46
 * @LastEditors: zhujun zhujun@yhb-work.com
 * @LastEditTime: 2024-09-30 10:41:46
 * @FilePath: \YHB_H5\src\pages\jcc\agreemnet.vue
 * @Description: 聚充充用户协议与隐私政策
-->
<template>
    <div class="content">
      <div v-html="content"></div>
    </div>
  </template>
  <script>
  import { getAgreementByType  } from '@/api/jcc'
  import { Toast } from 'vant';
  export default {
    data () {
      return {
        title: '',
        content: '',
        agreementList: [
            {id: 91, title: "聚充充用户服务协议"}, //（代理端）
            {id: 92, title: "聚充充用户隐私协议"}, //（代理端）
            {id: 93, title: "聚充充用户隐私协议"}, //（用户端）
            {id: 94, title: "聚充充用户服务协议"}, //（用户端）
            {id: 95, title: "聚充充平台使用规则"}, //（用户端）
            {id: 97, title: "聚充充充电功率说明"}, //（用户端）
        ]
      }
    },
    mounted(){
        let typeId = this.$route.query.typeId
        if(this.agreementList.find(item => item.id == typeId)){
            this.getInformation(typeId)
        }else {
          alert('未查到相关文件')
        }
    },
    methods: {
      getInformation (typeId) {
        getAgreementByType(typeId).then(res => {
          if (res.code != 200) {

            Toast({
              message: res.msg,
              duration: 3000
            });

            return;


          }
          this.title = res.data.title;
          document.title = res.data.title
          this.content = res.data.content
        })
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .content{
    padding:30px;
    line-height: 1.8;
    pre{
            white-space: pre-line;
    }
  }

  </style>
